import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import htmlToElement from "fp/html_to_element"

export default class extends Controller {
  static targets = [ "container" ]

  initialize() {
    this.loadable = true
    while(this.loadable && this.endOfPageInSight()) {
      this.loadMoreUpdateApartments()
    }
    this.onEndOfPage = this.onEndOfPage.bind(this)
    document.addEventListener('scroll', this.onEndOfPage)
  }

  disconnect() {
    document.removeEventListener('scroll', this.onEndOfPage)
  }

  onEndOfPage(e) {
    if(!this.endOfPageInSight()) return
    if(this.loadable) this.loadMoreUpdateApartments()
  }

  endOfPageInSight() {
    let el = this.containerTarget
    return Boolean(el.scrollHeight - window.scrollY < 600)
  }

  loadMoreUpdateApartments() {
    this.loadable = false
    let nextPage = parseInt(this.data.get("page")) + 1
    let options = { page: nextPage }
    if (this.data.get("folders-params")) options['folders_params'] = this.data.get("folders-params")
    Rails.ajax({
      type: 'GET',
      url: Routes[this.data.get("url")](options),
      success: ((data) => {
        if(data.html.trim()) {
          this.containerTarget.insertBefore(htmlToElement(data.html), null)
          this.data.set("page", nextPage)
          this.loadable = true
        }
      })
    })
  }
}
