import { Controller } from "stimulus"

export default class extends Controller {
  onShareClick() {
    let canonicalTag = document.querySelector('link[rel=canonical]')
    if (navigator.share) {
      let metaDescTag = document.querySelector("meta[name=description]")
      navigator.share({
        title: this.data.get("title") || document.querySelector('title').innerText,
        url: this.data.get("url") || (canonicalTag && canonicalTag.href) || document.location.href,
        text: this.data.get("text") || (metaDescTag && metaDescTag.content)
      })
    } else {
      let el = document.createElement('textarea')
      el.value = this.data.get("url") || (canonicalTag && canonicalTag.href) || document.location.href
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      let selected
      if (document.getSelection().rangeCount > 0) {
        selected = document.getSelection().getRangeAt(0)
      } else {
        selected = false
      }
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
      // TODO: I18n
      alert("Đường dẫn đã được sao chép thành công!")
    }
  }
}
