import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"
import _map from "fp/map"

export default class extends Controller {
  static targets = [ "image" ]

  initialize() {
    this.lightbox = new FsLightbox()
    this.lightbox.props.sources = _map(this.imageTargets, (img) => img.getAttribute("data-src"))
    this.lightbox.props.types = Array(this.imageTargets.length).fill("image")
    this.lightbox.setup()
    this.slider = tns(JSON.parse(this.data.get('config')))
    document.page_gallery = this.lightbox
  }

  disconnect() {
    this.slider.destroy()
  }

  onMouseDown(event) {
    this.startX = event.pageX;
    this.startY = event.pageY;
  }

  onMouseUp(event) {
    let diffX = Math.abs(event.pageX - this.startX)
    let diffY = Math.abs(event.pageY - this.startY)
    if(diffX < 6 && diffY < 6) {
      let clickedUrlIndex = event.target.getAttribute("src")
      this.lightbox.open(this.lightbox.props.sources.indexOf(clickedUrlIndex))
    }
  }
}
