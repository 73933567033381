import { Controller } from "stimulus"
import noUiSlider from "nouislider"

export default class extends Controller {
  static targets = [ "root", "fminInput", "fmaxInput", "fminText", "fmaxText" ]

  initialize() {
    this.slider = noUiSlider.create(this.rootTarget, {
      start: [parseInt(this.data.get('fmin')), parseInt(this.data.get('fmax'))],
      connect: true,
      range: {
        'min': parseInt(this.data.get('min')),
        'max': parseInt(this.data.get('max'))
      },
      // == Required to make purgeCSS works ==
      cssPrefix: '',
      cssClasses: {
        target:               "range-slider__target",
        base:                 "range-slider__base",
        origin:               "range-slider__origin",
        handle:               "range-slider__handle",
        handleLower:          "range-slider__handle-lower",
        handleUpper:          "range-slider__handle-upper",
        touchArea:            "range-slider__touch-area",
        horizontal:           "range-slider__horizontal",
        background:           "range-slider__background",
        connect:              "range-slider__connect",
        connects:             "range-slider__connects",
        ltr:                  "range-slider__ltr",
        rtl:                  "range-slider__rtl",
        textDirectionLtr:     "range-slider__txt-dir-ltr",
        textDirectionRtl:     "range-slider__txt-dir-rtl",
        draggable:            "range-slider__draggable",
        drag:                 "range-slider__state-drag",
        tap:                  "range-slider__state-tap",
        active:               "range-slider__active",
      },
      // == Required to make purgeCSS works ==
      step: parseInt(this.data.get('step'))
    })
    this.slider.on('update', function(values) {
      let min = parseInt(values[0])
      let max = parseInt(values[1])
      this.fminInputTarget.value = min
      this.fminTextTarget.innerHTML = `${min}${this.data.get('unit')}`
      this.fmaxInputTarget.value = max
      this.fmaxTextTarget.innerHTML = `${max}${this.data.get('unit')}`
    }.bind(this))
  }

  disconnect() {
    this.slider.destroy()
  }
}
