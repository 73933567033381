import { Controller } from "stimulus"
import fuzzysearch from "fuzzysearch"
import debounce from "fp/debounce"

export default class extends Controller {
  static targets = [ "searchInput", "item" ]

  connect() {
    this.onSearchInputChange = debounce(this.onSearchInputChange.bind(this), 250)
    this.searchInputTarget.addEventListener('input', this.onSearchInputChange)
    this.searchInputTarget.addEventListener('propertychange', this.onSearchInputChange) // IE8
  }

  disconnect() {
    this.searchInputTarget.removeEventListener('input', this.onSearchInputChange)
    this.searchInputTarget.removeEventListener('propertychange', this.onSearchInputChange)
  }

  onSearchInputChange(e) {
    this.itemTargets.forEach(element => {
      if (fuzzysearch(e.target.value.toLowerCase(), element.dataset.text.toLowerCase())) {
        element.style.display = 'block'
      } else {
        element.style.display = 'none'
      }
    })
  }
}
