import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import htmlToElement from "fp/html_to_element"

export default class extends Controller {
  static targets = [ "container", "buttonContainer" ]

  onLoadMore(e) {
    const btn = e.target
    btn.classList.add("is-loading")
    let nextPage = parseInt(this.data.get("page")) + 1

    Rails.ajax({
      type: 'GET',
      url: Routes.feed_ajax_load_more_apartments_url({ page: nextPage }),
      success: ((data) => {
        if(data.html.trim()) {
          this.containerTarget.insertBefore(htmlToElement(data.html), this.buttonContainerTarget)
          if(data.count < 10) {
            btn.remove()
          } else {
            this.data.set("page", nextPage)
            btn.classList.remove("is-loading")
          }
        } else {
          btn.remove()
        }
      })
    })
  }
}