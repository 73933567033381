import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "title" ]

  connect() {
    if (this.isStatic()) return
    this.titleTarget.style.color = "transparent"
    this.onScroll = this.onScroll.bind(this)
    window.addEventListener("scroll", this.onScroll)
  }

  disconnect() {
    if (this.isStatic()) return
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll() {
    let y = window.scrollY || window.pageYOffset
    if (y > 45) {
      this.containerTarget.classList.add("has-background-primary")
      this.titleTarget.style.color = "white"
    } else {
      this.containerTarget.classList.remove("has-background-primary")
      this.titleTarget.style.color = "transparent"
    }
  }

  isStatic() {
    return this.data.get("static") == 'true'
  }
}